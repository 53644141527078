import { render, staticRenderFns } from "./ProductItem.vue?vue&type=template&id=6eb0971a&scoped=true"
import script from "./ProductItem.vue?vue&type=script&lang=js"
export * from "./ProductItem.vue?vue&type=script&lang=js"
import style0 from "./ProductItem.vue?vue&type=style&index=0&id=6eb0971a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb0971a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductItemTitle: require('/codebuild/output/src1097930377/src/components/product-item/title/ProductItemTitle.vue').default,CommonProfile: require('/codebuild/output/src1097930377/src/components/common/CommonProfile.vue').default})
