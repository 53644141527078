import { render, staticRenderFns } from "./-talent.vue?vue&type=template&id=55406121&scoped=true"
import script from "./-talent.vue?vue&type=script&lang=js"
export * from "./-talent.vue?vue&type=script&lang=js"
import style0 from "./-talent.vue?vue&type=style&index=0&id=55406121&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55406121",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NewClassSection: require('/codebuild/output/src1097930377/src/components/new-home/common/new-class-section/NewClassSection.vue').default})
