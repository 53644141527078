import { render, staticRenderFns } from "./HomeRankingItem.vue?vue&type=template&id=f70a64a0&scoped=true"
import script from "./HomeRankingItem.vue?vue&type=script&lang=js"
export * from "./HomeRankingItem.vue?vue&type=script&lang=js"
import style0 from "./HomeRankingItem.vue?vue&type=style&index=0&id=f70a64a0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f70a64a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RankingThumbnail: require('/codebuild/output/src1097930377/src/components/ranking/rankingItem/common/RankingThumbnail.vue').default,RankingTitle: require('/codebuild/output/src1097930377/src/components/ranking/rankingItem/common/RankingTitle.vue').default,CommonProfile: require('/codebuild/output/src1097930377/src/components/common/CommonProfile.vue').default})
