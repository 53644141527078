import { render, staticRenderFns } from "./NewClassSection.vue?vue&type=template&id=1237dd32"
import script from "./NewClassSection.vue?vue&type=script&lang=js"
export * from "./NewClassSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ProductCuration: require('/codebuild/output/src1097930377/src/components/product-curation/ProductCuration.vue').default})
