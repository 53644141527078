import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _17a0200b = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _6357d841 = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _4a2fec12 = () => interopDefault(import('../pages/error.vue' /* webpackChunkName: "pages/error" */))
const _42732f27 = () => interopDefault(import('../pages/event/index.vue' /* webpackChunkName: "pages/event/index" */))
const _7eff4400 = () => interopDefault(import('../pages/introduction.vue' /* webpackChunkName: "pages/introduction" */))
const _7716b21d = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _dc4fd5d6 = () => interopDefault(import('../pages/my/index.vue' /* webpackChunkName: "pages/my/index" */))
const _7d66c041 = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _e13b4360 = () => interopDefault(import('../pages/private/index.vue' /* webpackChunkName: "pages/private/index" */))
const _bc74a288 = () => interopDefault(import('../pages/product/index.vue' /* webpackChunkName: "pages/product/index" */))
const _67ad2df0 = () => interopDefault(import('../pages/psych/index.vue' /* webpackChunkName: "pages/psych/index" */))
const _be99ae1a = () => interopDefault(import('../pages/psyche/index.vue' /* webpackChunkName: "pages/psyche/index" */))
const _9b00fd7a = () => interopDefault(import('../pages/ranking/index.vue' /* webpackChunkName: "pages/ranking/index" */))
const _469b5c99 = () => interopDefault(import('../pages/request.vue' /* webpackChunkName: "pages/request" */))
const _4554906e = () => interopDefault(import('../pages/roadmap/index.vue' /* webpackChunkName: "pages/roadmap/index" */))
const _6da30480 = () => interopDefault(import('../pages/schedule/index.vue' /* webpackChunkName: "pages/schedule/index" */))
const _7efd69de = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _6d19d9ef = () => interopDefault(import('../pages/teacher/index.vue' /* webpackChunkName: "pages/teacher/index" */))
const _323dac14 = () => interopDefault(import('../pages/auth/leave.vue' /* webpackChunkName: "pages/auth/leave" */))
const _67d6f7a8 = () => interopDefault(import('../pages/auth/leave-success.vue' /* webpackChunkName: "pages/auth/leave-success" */))
const _4987ff2e = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _1b45b0fc = () => interopDefault(import('../pages/auth/success.vue' /* webpackChunkName: "pages/auth/success" */))
const _3ad2c547 = () => interopDefault(import('../pages/card/fail.vue' /* webpackChunkName: "pages/card/fail" */))
const _2ba482f4 = () => interopDefault(import('../pages/card/result.vue' /* webpackChunkName: "pages/card/result" */))
const _851512d2 = () => interopDefault(import('../pages/my/alarm/index.vue' /* webpackChunkName: "pages/my/alarm/index" */))
const _f7c75b76 = () => interopDefault(import('../pages/my/authCode/index.vue' /* webpackChunkName: "pages/my/authCode/index" */))
const _b8cf2b1a = () => interopDefault(import('../pages/my/coupon.vue' /* webpackChunkName: "pages/my/coupon" */))
const _c5b72cc6 = () => interopDefault(import('../pages/my/favorites/index.vue' /* webpackChunkName: "pages/my/favorites/index" */))
const _825bc4d0 = () => interopDefault(import('../pages/my/friends.vue' /* webpackChunkName: "pages/my/friends" */))
const _4cb21e46 = () => interopDefault(import('../pages/my/money/index.vue' /* webpackChunkName: "pages/my/money/index" */))
const _42a32b54 = () => interopDefault(import('../pages/my/order/index.vue' /* webpackChunkName: "pages/my/order/index" */))
const _02ad6da2 = () => interopDefault(import('../pages/my/profile/index.vue' /* webpackChunkName: "pages/my/profile/index" */))
const _0545cd21 = () => interopDefault(import('../pages/my/reviews/index.vue' /* webpackChunkName: "pages/my/reviews/index" */))
const _10203b2d = () => interopDefault(import('../pages/my/subscription/index.vue' /* webpackChunkName: "pages/my/subscription/index" */))
const _21b61351 = () => interopDefault(import('../pages/my/vod/index.vue' /* webpackChunkName: "pages/my/vod/index" */))
const _17f52e31 = () => interopDefault(import('../pages/my/voucher.vue' /* webpackChunkName: "pages/my/voucher" */))
const _2e11d0ed = () => interopDefault(import('../pages/payment/fail.vue' /* webpackChunkName: "pages/payment/fail" */))
const _09d878ac = () => interopDefault(import('../pages/payment/result.vue' /* webpackChunkName: "pages/payment/result" */))
const _14fa06c4 = () => interopDefault(import('../pages/private/banner.vue' /* webpackChunkName: "pages/private/banner" */))
const _44119b13 = () => interopDefault(import('../pages/private/commonTextAreaField.vue' /* webpackChunkName: "pages/private/commonTextAreaField" */))
const _c1f7dcaa = () => interopDefault(import('../pages/private/complete.vue' /* webpackChunkName: "pages/private/complete" */))
const _80a792ea = () => interopDefault(import('../pages/private/preGuide.vue' /* webpackChunkName: "pages/private/preGuide" */))
const _68ad4a3c = () => interopDefault(import('../pages/private/privateRequestForm.vue' /* webpackChunkName: "pages/private/privateRequestForm" */))
const _34f71e5e = () => interopDefault(import('../pages/private/processGuideModal.vue' /* webpackChunkName: "pages/private/processGuideModal" */))
const _49fd0024 = () => interopDefault(import('../pages/product/suggest.vue' /* webpackChunkName: "pages/product/suggest" */))
const _4b38c80a = () => interopDefault(import('../pages/psyche/guidance/index.vue' /* webpackChunkName: "pages/psyche/guidance/index" */))
const _40790040 = () => interopDefault(import('../pages/psyche/huno/index.vue' /* webpackChunkName: "pages/psyche/huno/index" */))
const _3c3f854a = () => interopDefault(import('../pages/teacher/search.vue' /* webpackChunkName: "pages/teacher/search" */))
const _f1f0394c = () => interopDefault(import('../pages/my/money/fail.vue' /* webpackChunkName: "pages/my/money/fail" */))
const _3358473f = () => interopDefault(import('../pages/my/money/fill.vue' /* webpackChunkName: "pages/my/money/fill" */))
const _04d8b84e = () => interopDefault(import('../pages/my/money/result.vue' /* webpackChunkName: "pages/my/money/result" */))
const _7c344f46 = () => interopDefault(import('../pages/my/money/withdraw.vue' /* webpackChunkName: "pages/my/money/withdraw" */))
const _14981898 = () => interopDefault(import('../pages/my/money/withdraw-result.vue' /* webpackChunkName: "pages/my/money/withdraw-result" */))
const _3bfa7e2a = () => interopDefault(import('../pages/my/order/cancel/index.vue' /* webpackChunkName: "pages/my/order/cancel/index" */))
const _291ee079 = () => interopDefault(import('../pages/my/profile/child.vue' /* webpackChunkName: "pages/my/profile/child" */))
const _c5344a1c = () => interopDefault(import('../pages/my/subscription/cancel/index.vue' /* webpackChunkName: "pages/my/subscription/cancel/index" */))
const _e733a54a = () => interopDefault(import('../pages/my/subscription/confirm.vue' /* webpackChunkName: "pages/my/subscription/confirm" */))
const _564f7c40 = () => interopDefault(import('../pages/schedule/cancel/result.vue' /* webpackChunkName: "pages/schedule/cancel/result" */))
const _0669cddc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c77d20e8 = () => interopDefault(import('../pages/my/reviews/_id/index.vue' /* webpackChunkName: "pages/my/reviews/_id/index" */))
const _62383934 = () => interopDefault(import('../pages/product/curation/_id.vue' /* webpackChunkName: "pages/product/curation/_id" */))
const _498226c2 = () => interopDefault(import('../pages/psyche/guidance/_slug.vue' /* webpackChunkName: "pages/psyche/guidance/_slug" */))
const _3ec25ef8 = () => interopDefault(import('../pages/psyche/huno/_slug.vue' /* webpackChunkName: "pages/psyche/huno/_slug" */))
const _c023095a = () => interopDefault(import('../pages/my/reviews/_id/result.vue' /* webpackChunkName: "pages/my/reviews/_id/result" */))
const _7f50d174 = () => interopDefault(import('../pages/my/order/_type/_id.vue' /* webpackChunkName: "pages/my/order/_type/_id" */))
const _15e97ec3 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _40bc8ddf = () => interopDefault(import('../pages/event/_slug.vue' /* webpackChunkName: "pages/event/_slug" */))
const _8a8e1dbc = () => interopDefault(import('../pages/event/_slug/index.vue' /* webpackChunkName: "pages/event/_slug/index" */))
const _f70c422e = () => interopDefault(import('../pages/notice/_id.vue' /* webpackChunkName: "pages/notice/_id" */))
const _c9af9fb8 = () => interopDefault(import('../pages/product/_id.vue' /* webpackChunkName: "pages/product/_id" */))
const _4c710ba7 = () => interopDefault(import('../pages/product/_id/index.vue' /* webpackChunkName: "pages/product/_id/index" */))
const _f12600ca = () => interopDefault(import('../pages/product/_id/payment.vue' /* webpackChunkName: "pages/product/_id/payment" */))
const _b0f444c2 = () => interopDefault(import('../pages/product/_id/subscribe.vue' /* webpackChunkName: "pages/product/_id/subscribe" */))
const _45c1c4b6 = () => interopDefault(import('../pages/product/_id/notice/_noticeId.vue' /* webpackChunkName: "pages/product/_id/notice/_noticeId" */))
const _6b1a7080 = () => interopDefault(import('../pages/psych/_slug.vue' /* webpackChunkName: "pages/psych/_slug" */))
const _855dcf1e = () => interopDefault(import('../pages/roadmap/_id.vue' /* webpackChunkName: "pages/roadmap/_id" */))
const _80e87398 = () => interopDefault(import('../pages/roadmap/_id/index.vue' /* webpackChunkName: "pages/roadmap/_id/index" */))
const _078ac95b = () => interopDefault(import('../pages/schedule/_hash.vue' /* webpackChunkName: "pages/schedule/_hash" */))
const _009363b5 = () => interopDefault(import('../pages/schedule/_hash/detail.vue' /* webpackChunkName: "pages/schedule/_hash/detail" */))
const _b038e7d2 = () => interopDefault(import('../pages/teacher/_id.vue' /* webpackChunkName: "pages/teacher/_id" */))
const _3e94d8bc = () => interopDefault(import('../pages/coupon/_id/usable-product.vue' /* webpackChunkName: "pages/coupon/_id/usable-product" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _17a0200b,
    name: "blog"
  }, {
    path: "/chat",
    component: _6357d841,
    name: "chat"
  }, {
    path: "/error",
    component: _4a2fec12,
    name: "error"
  }, {
    path: "/event",
    component: _42732f27,
    name: "event"
  }, {
    path: "/introduction",
    component: _7eff4400,
    name: "introduction"
  }, {
    path: "/maintenance",
    component: _7716b21d,
    name: "maintenance"
  }, {
    path: "/my",
    component: _dc4fd5d6,
    name: "my"
  }, {
    path: "/notice",
    component: _7d66c041,
    name: "notice"
  }, {
    path: "/private",
    component: _e13b4360,
    name: "private"
  }, {
    path: "/product",
    component: _bc74a288,
    name: "product"
  }, {
    path: "/psych",
    component: _67ad2df0,
    name: "psych"
  }, {
    path: "/psyche",
    component: _be99ae1a,
    name: "psyche"
  }, {
    path: "/ranking",
    component: _9b00fd7a,
    name: "ranking"
  }, {
    path: "/request",
    component: _469b5c99,
    name: "request"
  }, {
    path: "/roadmap",
    component: _4554906e,
    name: "roadmap"
  }, {
    path: "/schedule",
    component: _6da30480,
    name: "schedule"
  }, {
    path: "/search",
    component: _7efd69de,
    name: "search"
  }, {
    path: "/teacher",
    component: _6d19d9ef,
    name: "teacher"
  }, {
    path: "/auth/leave",
    component: _323dac14,
    name: "auth-leave"
  }, {
    path: "/auth/leave-success",
    component: _67d6f7a8,
    name: "auth-leave-success"
  }, {
    path: "/auth/signup",
    component: _4987ff2e,
    name: "auth-signup"
  }, {
    path: "/auth/success",
    component: _1b45b0fc,
    name: "auth-success"
  }, {
    path: "/card/fail",
    component: _3ad2c547,
    name: "card-fail"
  }, {
    path: "/card/result",
    component: _2ba482f4,
    name: "card-result"
  }, {
    path: "/my/alarm",
    component: _851512d2,
    name: "my-alarm"
  }, {
    path: "/my/authCode",
    component: _f7c75b76,
    name: "my-authCode"
  }, {
    path: "/my/coupon",
    component: _b8cf2b1a,
    name: "my-coupon"
  }, {
    path: "/my/favorites",
    component: _c5b72cc6,
    name: "my-favorites"
  }, {
    path: "/my/friends",
    component: _825bc4d0,
    name: "my-friends"
  }, {
    path: "/my/money",
    component: _4cb21e46,
    name: "my-money"
  }, {
    path: "/my/order",
    component: _42a32b54,
    name: "my-order"
  }, {
    path: "/my/profile",
    component: _02ad6da2,
    name: "my-profile"
  }, {
    path: "/my/reviews",
    component: _0545cd21,
    name: "my-reviews"
  }, {
    path: "/my/subscription",
    component: _10203b2d,
    name: "my-subscription"
  }, {
    path: "/my/vod",
    component: _21b61351,
    name: "my-vod"
  }, {
    path: "/my/voucher",
    component: _17f52e31,
    name: "my-voucher"
  }, {
    path: "/payment/fail",
    component: _2e11d0ed,
    name: "payment-fail"
  }, {
    path: "/payment/result",
    component: _09d878ac,
    name: "payment-result"
  }, {
    path: "/private/banner",
    component: _14fa06c4,
    name: "private-banner"
  }, {
    path: "/private/commonTextAreaField",
    component: _44119b13,
    name: "private-commonTextAreaField"
  }, {
    path: "/private/complete",
    component: _c1f7dcaa,
    name: "private-complete"
  }, {
    path: "/private/preGuide",
    component: _80a792ea,
    name: "private-preGuide"
  }, {
    path: "/private/privateRequestForm",
    component: _68ad4a3c,
    name: "private-privateRequestForm"
  }, {
    path: "/private/processGuideModal",
    component: _34f71e5e,
    name: "private-processGuideModal"
  }, {
    path: "/product/suggest",
    component: _49fd0024,
    name: "product-suggest"
  }, {
    path: "/psyche/guidance",
    component: _4b38c80a,
    name: "psyche-guidance"
  }, {
    path: "/psyche/huno",
    component: _40790040,
    name: "psyche-huno"
  }, {
    path: "/teacher/search",
    component: _3c3f854a,
    name: "teacher-search"
  }, {
    path: "/my/money/fail",
    component: _f1f0394c,
    name: "my-money-fail"
  }, {
    path: "/my/money/fill",
    component: _3358473f,
    name: "my-money-fill"
  }, {
    path: "/my/money/result",
    component: _04d8b84e,
    name: "my-money-result"
  }, {
    path: "/my/money/withdraw",
    component: _7c344f46,
    name: "my-money-withdraw"
  }, {
    path: "/my/money/withdraw-result",
    component: _14981898,
    name: "my-money-withdraw-result"
  }, {
    path: "/my/order/cancel",
    component: _3bfa7e2a,
    name: "my-order-cancel"
  }, {
    path: "/my/profile/child",
    component: _291ee079,
    name: "my-profile-child"
  }, {
    path: "/my/subscription/cancel",
    component: _c5344a1c,
    name: "my-subscription-cancel"
  }, {
    path: "/my/subscription/confirm",
    component: _e733a54a,
    name: "my-subscription-confirm"
  }, {
    path: "/schedule/cancel/result",
    component: _564f7c40,
    name: "schedule-cancel-result"
  }, {
    path: "/",
    component: _0669cddc,
    name: "index"
  }, {
    path: "/my/reviews/:id",
    component: _c77d20e8,
    name: "my-reviews-id"
  }, {
    path: "/product/curation/:id?",
    component: _62383934,
    name: "product-curation-id"
  }, {
    path: "/psyche/guidance/:slug",
    component: _498226c2,
    name: "psyche-guidance-slug"
  }, {
    path: "/psyche/huno/:slug",
    component: _3ec25ef8,
    name: "psyche-huno-slug"
  }, {
    path: "/my/reviews/:id/result",
    component: _c023095a,
    name: "my-reviews-id-result"
  }, {
    path: "/my/order/:type/:id?",
    component: _7f50d174,
    name: "my-order-type-id"
  }, {
    path: "/blog/:slug",
    component: _15e97ec3,
    name: "blog-slug"
  }, {
    path: "/event/:slug",
    component: _40bc8ddf,
    children: [{
      path: "",
      component: _8a8e1dbc,
      name: "event-slug"
    }]
  }, {
    path: "/notice/:id",
    component: _f70c422e,
    name: "notice-id"
  }, {
    path: "/product/:id",
    component: _c9af9fb8,
    children: [{
      path: "",
      component: _4c710ba7,
      name: "product-id"
    }, {
      path: "payment",
      component: _f12600ca,
      name: "product-id-payment"
    }, {
      path: "subscribe",
      component: _b0f444c2,
      name: "product-id-subscribe"
    }, {
      path: "notice/:noticeId?",
      component: _45c1c4b6,
      name: "product-id-notice-noticeId"
    }]
  }, {
    path: "/psych/:slug",
    component: _6b1a7080,
    name: "psych-slug"
  }, {
    path: "/roadmap/:id",
    component: _855dcf1e,
    children: [{
      path: "",
      component: _80e87398,
      name: "roadmap-id"
    }]
  }, {
    path: "/schedule/:hash",
    component: _078ac95b,
    name: "schedule-hash",
    children: [{
      path: "detail",
      component: _009363b5,
      name: "schedule-hash-detail"
    }]
  }, {
    path: "/teacher/:id",
    component: _b038e7d2,
    name: "teacher-id"
  }, {
    path: "/coupon/:id?/usable-product",
    component: _3e94d8bc,
    name: "coupon-id-usable-product"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
